import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ data }) => {
  const title = get(data, "contentfulContactPage.title")
  const html = get(data, "contentfulContactPage.body.childMarkdownRemark.html")

  return (
    <>
      <SEO title={title} keywords="contact, info, question, vraag" />
      <Layout>
        <section>
          <h1>{title}</h1>
          <div
            className="mb-8 html"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <form
            action="/thanks-contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            className="w-full max-w-lg"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                <label className="label" htmlFor="firstname">
                  Voornaam
                </label>
                <input
                  className="input"
                  name="firstname"
                  id="firstname"
                  type="text"
                  required
                />
                {/* <p className="text-xs italic text-red-500">Please fill out this field.</p> */}
              </div>
              <div className="w-full px-3 md:w-1/2">
                <label className="label" htmlFor="lastname">
                  Achternaam
                </label>
                <input
                  className="input"
                  name="lastname"
                  id="lastname"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="email">
                  Email
                </label>
                <input className="input" name="email" id="email" type="email" required />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 -mx-3">
              <div className="w-full px-3">
                <label className="label" htmlFor="message">
                  Bericht
                </label>
                <textarea
                  className="textarea"
                  name="message"
                  id="message"
                ></textarea>
              </div>
            </div>
            <div className="mt-10 md:flex md:items-center">
              <div className="md:w-1/3">
                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white rounded shadow bg-primary-700 hover:bg-primary-700 focus:shadow-outline focus:outline-none"
                >
                  Verzenden
                </button>
              </div>
              <div className="md:w-2/3"></div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ContactQuery {
    contentfulContactPage(contentful_id: { eq: "2ArmRlE6YbiUgJkzsSMp7d" }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default ContactPage
